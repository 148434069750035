import React from 'react'
import Layout from '../containers/Layout'
import repairs from '../config/text/repairs'

class Repairs extends React.Component {
  render () {
    return (
      <Layout heroSrc='heros/espresso-coffee-machine-repairs.jpg' heroText={repairs.title} title={repairs.meta.title} description={repairs.meta.description}>
        <div className='repairs container'>
          <div className='row'>
            <div className='col-xs-12'>
              {repairs.description.map(desc => <p>{desc}</p>)}
            </div>
          </div>
            {repairs.list.map(item => {
              return (
                <div className='row my-3'>
                  <div className='col-xs-12'>
                    {item.footnote ? (
                      <div className='mb-2'>
                        <h4 className='mb-0'>{item.title}</h4>
                        <small className='text-muted'>{item.footnote}</small>
                      </div>
                    ) : (
                      <h4>{item.title}</h4>
                    )}
                    {item.description &&
                      <p>{item.description}</p>
                    }
                    {item.points && 
                      <ul>
                        {item.points.map(i => <li>{i}</li>)}
                      </ul>
                    }
                  </div>
                </div>
              )
            })}
        </div>
      </Layout>
    )
  }
}

export default Repairs
